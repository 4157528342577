import React from "react";
import "./App.css";
import Header from "./Header";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
function Expire() {
  return (
    <>
      <Header />
      <div className="container ipad-container">
        <div
          className="row res-card-row"
          style={{ margin: "0 auto", justifyContent: "center" }}
        >
          <div className="col-lg-8 col-md-12 col-sm-12 order-sm-2 order-md-2 order-2">
            <div
              className="formobicard"
              style={{
                marginTop: "7em",
                textAlign: "center",
                borderRadius: "5px",
                background: "#FFF",
                boxShadow: "5px 4px 22.4px -1px rgba(0, 0, 0, 0.10)",
                padding: "36px 40px",
              }}
            >
              <img
                src="https://d1qhh2rihbnflk.cloudfront.net/images/Link%20Expiry.svg"
                style={{ width: "197px", height: "119px" }}
                alt="Centered Image"
              />
              <h2
                className="inv-dt"
                style={{
                  fontSize: "22px",
                  color: "#344767",
                  fontWeight: "700",
                  margin: "20px 0",
                }}
              >
                This invoice has been paid!
              </h2>
              <p
                className="inv-dt"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#1D2B36",
                  textAlign: "center",
                  margin: "0",
                }}
              >
                If you need any further help or have any questions, feel free to
                reach out to our Sales Team at{" "}
                <a
                  style={{ color: "#337AB7", textDecoration: "none" }}
                  href="={mailto:${sales@transcredit.com}`}"
                  onClick={(event) => {
                    event.preventDefault();
                    window.location.href = `mailto: ${"sales@transcredit.com"}`;
                  }}
                >
                  sales@transcredit.com
                </a>
              </p>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <button
                className="loginbtnpage"
                onClick={() => {
                  window.open("https://portal.transcredit.com/", "_blank");
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          alignSelf: "end",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <AppBar position="static" sx={{ background: "#002b5c" }}>
          <Toolbar sx={{ textAlign: "center" }}>
            <Typography
              className="copy-text"
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                fontSize: "12px",
                color: "#6bc1cd",
                fontWeight: "400",
                marginTop: "10px",
              }}
            >
              � 2024 - Transcredit, Inc. - All Rights Reserved Licensed & Bonded
              | Licence No. COM 9900337 | State of Florida Department of Banking
              & Finance
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

export default Expire;
