import React from "react";
import { BrowserRouter,Route,Routes } from "react-router-dom";
import Paymentmethod from './paymentmethod'
import  Addon  from "./Addon";
import Expire  from "./Expire";
function App() {
  

  return (
    <BrowserRouter>
    <Routes>
      <Route index path="/" element={<Addon/>}/>
      <Route path="/paymentmethod" element={<Paymentmethod />}/>
      <Route path='/expired' element={<Expire />}/>
    </Routes>
    </BrowserRouter>
  )
}

export default App