import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Header from './Header';
import { Button } from 'react-bootstrap';

const Payment=({backHome,values})=>{

  const redirect=()=>{
    window.open('https://portal.transcredit.com/','_blank');
  }
  
return(

    <>
 {/* <Header /> */}
{values==1 ?
 <div className='container ipad-container'>
        <div className='row res-card-row' style={{ margin: '0 auto', justifyContent: 'center' }}>
          <div className='col-lg-8 col-md-12 col-sm-12 order-sm-2 order-md-2 order-2'>
            <div className="formobicard" style={{ marginTop: '7em', textAlign: 'center', borderRadius: '5px', background: '#FFF', boxShadow: '5px 4px 22.4px -1px rgba(0, 0, 0, 0.10)', padding: '36px 40px' }}>
              <img src="https://d1qhh2rihbnflk.cloudfront.net/images/Payment%20Failure.svg" style={{ width: '197px', height: '119px' }} alt="Centered Image" />
              <h2 className='inv-dt' style={{ fontSize: '24px', color: '#344767', fontWeight: '700', margin: '20px 0' }}>Payment Failed!</h2>
              <p className='inv-dt' style={{ fontSize: '16px', fontWeight: '500', color: '#1D2B36', textAlign: 'center', margin: '0' }}> Opps! Your payment has failed, please review your payment details and try again.<br/>Thank you</p>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px',display:'flex', gap:'20px', justifyContent:'center', alignItems:'center' }}>
              <button className="backtohome" onClick={()=>{backHome('Failed')}}>Back to Home</button>
              {/* <button className="loginbtnpage" onClick={()=>{redirect()}}>Login</button> */}
            </div>
          </div>
        </div>
      </div>
      :
      <div className='container ipad-container'>
        <div className='row res-card-row' style={{ margin: '0 auto', justifyContent: 'center' }}>
          <div className='col-lg-8 col-md-12 col-sm-12 order-sm-2 order-md-2 order-2'>
            <div className="formobicard" style={{ marginTop: '7em', textAlign: 'center', borderRadius: '5px', background: '#FFF', boxShadow: '5px 4px 22.4px -1px rgba(0, 0, 0, 0.10)', padding: '36px 40px' }}>
              <img src="https://d1qhh2rihbnflk.cloudfront.net/images/ACH%20payment%20failed.svg" style={{ width: '197px', height: '119px' }} alt="Centered Image" />
              <h2 className='inv-dt' style={{ fontSize: '24px', color: '#344767', fontWeight: '700', margin: '20px 0' }}>Payment Failed!</h2>
              <p className='inv-dt' style={{ fontSize: '16px', fontWeight: '500', color: '#1D2B36', textAlign: 'center', margin: '0' }}> Opps! Your payment has failed, please review your payment details and try again.<br/>Thank you</p>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px',display:'flex', gap:'20px', justifyContent:'center', alignItems:'center' }}>
              <button className="backtohome" onClick={()=>{backHome('Failed')}}>Back to Home</button>
              {/* <button className="loginbtnpage" onClick={()=>{redirect()}}>Login</button> */}
            </div>
          </div>
        </div>
      </div>

}
    </>
)


}
export default Payment;

