import React, { useState, useEffect, useRef } from 'react';
import './App.css';



function Header(){

return(

    <>
 <div className='container-fluid' style={{ background: '#FFF', padding: '4px 38px 0px 38px', position: 'fixed', boxShadow: 'rgba(0,0,0,0.25)0px 0px 4px 0px', zIndex: '99' }}>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='row header'>
              <div className='col-lg-10 col-md-10 col-sm-10 header-xs-10'>
                <div className='header-row' style={{ display: 'flex', marginTop: '20px', gap: '10px' }}>
                  <div className='header-addres'><h4 className='header-address' style={{ fontSize: '18px', margin: '0', color: '#003876' }}>Transcredit Inc - 1309 St Johns Bluff Road North Ste 103
                    Jacksonville, FL 32225</h4></div>
                  <div><button  className='toll-btn' style={{ marginTop: '-5px', padding: '3px 10px', border: '1px solid #bfcc5c', background: '#bfcc5c', color: '#003876', borderRadius: '3px', fontWeight: '600', whiteSpace: 'nowrap' }}>
                    <span style={{ display: 'flex', gap: '5px' }}>
                      <i className="bi bi-telephone-fill tele-icon" style={{ fontSize: '15px', marginTop: '2px' }}></i>
                      <a href='tel:1-800-215-8448' style={{color: '#003876',textDecoration:'none'}}>
                      <span>(800) 215-8448</span>
                      </a>
                    </span>
                  </button></div>
                </div>
              </div>
              <div className='col-lg-2 col-md-2 col-sm-2  header-xs-2' style={{ textAlign: 'right' }}>
                <img className='header-logo' src='https://d1qhh2rihbnflk.cloudfront.net/images/Transcredit_Logo.png' />
              </div>
            </div>
          </div>
        </div>
    </>
)


}
export default Header;

