import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import { Button } from "antd";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
function Addon() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const term = queryParams.get("accountid");

  const [programs, setprograms] = useState({ addon: "2" });
  const [total, settotal] = useState(0);
  const [addon, setaddon] = useState("");
  const [checked, setchecked] = useState();
  const [expired, setExpired] = useState(false);
  const [loading, setloading] = useState(false);
  const [checkedamt, setcheckedamt] = useState(0);
  const getapi = async () => {
    await axios
      .get(
        "https://salesapi.transcredit.com/api/Prospect/GetAccountDetails?accountid1=" +
          term
      )
      .then((response) => {
        if (response.data.msg === "Link Expire - Already Paid") {
          //   setStatus(true);
          setExpired(true);
        } else {
          setprograms({
            price:
              response.data?.Addonname === "Portfolio"
                ? (parseFloat(response.data.amt) + 100).toFixed(2)
                : response.data?.Addonname === "AR"
                ? (parseFloat(response.data.amt) - 0).toFixed(2)
                : parseFloat(response.data.amt).toFixed(2),
            product: response.data.product,
            program: response.data.program,
            payid: response.data.payid,
            addon: response.data.Addon,
            Addonname: response.data.Addonname,
          });

          if (response.data?.Addonname === "Portfolio") {
            setchecked("Portfolio");
            setcheckedamt(100.0);
          } else if (response.data?.Addonname === "AR") {
            setchecked("AR");
            setcheckedamt(0.0);
          }

          settotal(parseFloat(response.data.amt));
          console.log(
            (parseFloat(response.data.amt) + 100).toFixed(2),
            "total"
          );
          setaddon(response.data.Addonname);
        }
      })

      .catch((err) => console.log(err));
  };

  const checkchange = (e) => {
    console.log(e, "e");

    if (e.target.checked) {
      if (e.target.name === "Portfolio") {
        setprograms((prev) => ({ ...prev, price: (total + 100).toFixed(2) }));
        setaddon(e.target.name);
        setchecked(e.target.name);
        setcheckedamt(100.0);
      } else {
        setprograms((prev) => ({ ...prev, price: (total - 0).toFixed(2) }));
        setaddon(e.target.name);
        setchecked(e.target.name);
        setcheckedamt(0.0);
      }
    } else {
      setprograms((prev) => ({ ...prev, price: total.toFixed(2) }));
      setaddon("");
      setchecked("");
      setcheckedamt(0);
    }
  };

  useEffect(() => {
    getapi();
  }, []);

  const next = async () => {
    try {
      setloading(true);

      const payload = {
        payid: programs.payid,
        addon: addon,
        price: checkedamt,
      };

      const res = await axios.post(
        "https://salesapi.transcredit.com/api/Prospect/Addonpackage/",
        payload
      );

      if (res.data.msg === "Added") {
        if (addon !== "") {
          toast.success(`Successfully ${res.data.msg}`);
        }
        setTimeout(() => {
          navigate("/paymentmethod?accountid=" + term);
          setloading(false);
        }, 2000);
        // setloading(false);
      } else {
        toast.failed(res.data.msg);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  console.log(checked, checkedamt, "checked");
  return (
    <>
      <div>
        <Header />
        {expired ? (
          navigate("/expired")
        ) : programs.addon === "2" ? (
          <Box
            sx={{
              display: "flex",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ zIndex: "99" }} />
          </Box>
        ) : (
          <>
            {programs.addon === "1" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="addon-grid">
                    <div className="addon-section">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            color: "#344767",
                            fontFamily: "sans-serif",
                            fontWeight: "600",
                            fontSize: "25px",
                          }}
                        >
                          Program
                        </p>
                        <p
                          style={{
                            color: "#003876",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Total:${programs.price}
                        </p>
                      </div>
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  color: "#8D8D94",
                                  paddingBottom: "10px",
                                  fontSize: "15px",
                                }}
                              >
                                Program
                              </td>
                              <td
                                style={{
                                  paddingLeft: "2.5rem",
                                  fontWeight: "600",
                                  paddingBottom: "10px",
                                  fontSize: "15px",
                                }}
                              >
                                {programs.program}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  color: "#8D8D94",
                                  paddingBottom: "10px",
                                  fontSize: "15px",
                                }}
                              >
                                Product
                              </td>
                              <td
                                style={{
                                  paddingLeft: "2.5rem",
                                  fontWeight: "600",
                                  paddingBottom: "10px",
                                  fontSize: "15px",
                                }}
                              >
                                {programs.product}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  color: "#8D8D94",
                                  paddingBottom: "10px",
                                  fontSize: "15px",
                                }}
                              >
                                Price
                              </td>
                              <td
                                style={{
                                  paddingLeft: "2.5rem",
                                  fontWeight: "600",
                                  paddingBottom: "10px",
                                  fontSize: "15px",
                                }}
                              >
                                ${total.toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <div
                          style={{
                            border: "1px solid #DEE2E6",
                            padding: "20px",
                            borderRadius: "10px",
                            background: "#F3F8FD",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "600",
                              marginBottom: "10px",
                              fontSize: "15px",
                              color: "#212529",
                            }}
                          >
                            Pick Optional Add-ons
                          </p>
                          <div
                            style={{
                              display: "flex",
                              marginBottom: "10px",
                              alignItems: "flex-start",
                            }}
                          >
                            <Checkbox
                              {...label}
                              name="Portfolio"
                              checked={checked === "Portfolio"}
                              value={true}
                              onChange={(e) => {
                                checkchange(e);
                              }}
                            />
                            {/* <input
                              type="checkbox"
                              name="Portfolio"
                              onChange={(e) => {
                                checkchange(e)
                              }}
                            /> */}
                            <span
                              style={{
                                color: "#003876",
                                marginLeft: "10px",
                                fontWeight: "500",
                              }}
                            >
                              $100 per/year optional add on for the Credit
                              Monitoring Portfolio (Store and monitor ordered
                              reports)
                            </span>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <Checkbox
                              {...label}
                              name="AR"
                              checked={checked === "AR"}
                              value={checked?.AR}
                              onChange={(e) => {
                                checkchange(e);
                              }}
                            />
                            {/* <input
                              type="checkbox"
                              name="AR"
                              onChange={(e) => {
                                checkchange(e)
                              }}
                            /> */}
                            <span
                              style={{
                                color: "#003876",
                                marginLeft: "10px",
                                fontWeight: "500",
                              }}
                            >
                              We will submit our company’s aging file on a
                              monthly basis to have our payment experiences
                              included in the TransCredit database and the $100
                              portfolio fee waived.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "7em",
                        marginBottom: "1em",
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: "5px",
                          background: "#3C96EE",
                          color: "#fff",
                          border: "1px solid #3C96EE",
                          padding: "20px 40px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          next();
                        }}
                        loading={loading}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>

                {/* <div
              className="row"
              style={{
                position: "absolute",
                // top: "100px",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "sans-serif",
                fontWeight: "600",
              }}
            >
              <div
                className="col-lg-12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                  height: "600px",
                  gap: "30px",
                }}
              >
                <div className="row">
                  <div
                    className="col"
                    style={{
                      backgroundColor: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      height: "450px",
                      paddingInline:'25px'
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: " #344767",
                          fontFamily: "sans-serif",
                          fontWeight: "600",
                          fontSize:'23px'
                        }}
                      >
                        Program
                      </p>
                      <p style={{ color: "#043B78",fontSize:'23px' }}>Total:${total}</p>
                    </div>

                    <div className="row" style={{ height: "100px" }}>
                      <div
                        className="col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="row">
                          <div className="col-1">
                            <h6>
                              <span
                                style={{
                                  color: "gray",
                                  fontFamily: "sans-serif",
                                }}
                              >
                                Program
                              </span>
                            </h6>
                          </div>
                          <div className="col-10">
                            <h6
                              style={{
                                fontFamily: "sans-serif",
                                fontWeight: "600",
                              }}
                            >
                              {programs.program}
                            </h6>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-1">
                            <h6>
                              <span style={{ color: "gray" }}>Product</span>
                            </h6>
                          </div>
                          <div className="col-10">
                            <h6
                              style={{
                                fontFamily: "sans-serif",
                                fontWeight: "600",
                              }}
                            >
                              {programs.product}
                            </h6>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-1">
                            <h6>
                              <span style={{ color: "gray" }}>Price</span>
                            </h6>
                          </div>
                          <div className="col-10">
                            <h6
                              style={{
                                fontFamily: "sans-serif",
                                fontWeight: "600",
                              }}
                            >
                              ${programs.price}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "180px",
                      }}
                    >
                      <div
                        className="col"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid gray",
                          borderRadius: "12px",
                          backgroundColor: "#F3F8FD",
                          justifyContent: "space-evenly",
                          width: "98%",
                        }}
                      >
                        <div className="row">
                          <span>Pick Optional Add-ons</span>
                        </div>

                        <div className="row">
                          <div>
                            <input
                              type="checkbox"
                              name="Portfolio"
                              onChange={(e) => {
                                checkchange(e)
                              }}
                            />
                            <span style={{ color: "#043B78" ,marginLeft:'10px'}}>
                              $100 per/year optional add on for the credit on
                              for the Credit Monitoring
                            </span>
                          </div>
                        </div>

                        <div className="row">
                          <div>
                            <input
                              type="checkbox"
                              name="AR"
                              onChange={(e) => {
                                checkchange(e)
                              }}
                            />
                            <span style={{ color: "#043B78" ,marginLeft:'10px'}}>
                              We will submit our Company's aging file on a
                              monthluy basis to have our payment experience
                              includes in the transcredit database and the $100
                              portfolio fee waived
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      style={{
                        padding: "5px 35px",
                        borderRadius: "2px",
                        border: "none",
                        backgroundColor: "#3C96EE",
                        color: "white",
                      }}
                      onClick={() => {
                        next();
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
              </>
            ) : (
              navigate("/paymentmethod?accountid=" + term)
            )}
          </>
        )}
        <Box
          sx={{
            flexGrow: 1,
            alignSelf: "end",
            position: "fixed",
            bottom: "0",
            width: "100%",
          }}
        >
          <AppBar position="static" sx={{ background: "#002b5c" }}>
            <Toolbar sx={{ textAlign: "center" }}>
              <Typography
                className="copy-text"
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 1,
                  fontSize: "12px",
                  color: "#6bc1cd",
                  fontWeight: "400",
                  marginTop: "10px",
                }}
              >
                © 2024 - Transcredit, Inc. - All Rights Reserved Licensed &
                Bonded | Licence No. COM 9900337 | State of Florida Department
                of Banking & Finance
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
}

export default Addon;
